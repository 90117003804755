import { useEffect, useState, lazy, Suspense } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import clsx from 'clsx';

import UnmountListeningWrapper from 'src/components/unmountListeningWrapper/UnmountListeningWrapper';
import { IPatientData } from 'src/models/app.models';
import { RoutePath } from 'src/models/table.models';
import { checkUrlPatientDetails } from 'src/helpers/checkUrlPatientDetails';
import { parseOktaData } from 'src/helpers/parseOktaData';
import { generateReportFileName } from 'src/helpers/generateReportFileName';

import { isEmpty } from '../helpers/objectVerification';
import { getPatientInfo } from '../helpers/services/PatientInfoService';
import Loading from '../components/loading/Loading';

const ReportSummary = lazy(() => import('../components/reportSummary/ReportSummary'));
const ReportDaily = lazy(() => import('../components/reportDaily/ReportDaily'));
const ReportSettings = lazy(() => import('src/components/reportSettings/ReportSettings'));

export const reportFileNameElementId = 'patient-details__file-name';

enum PatientDetailsLoadingStep {
    FetchData = 'fetch-data',
    RenderSummary = 'render-summary',
    RenderDaily = 'render-daily',
    RenderSettings = 'render-settings',
}

const PatientDetails = () => {
    const history = useHistory();
    const authState = useOktaAuth()?.authState;
    const [patientInfo, setPatientInfo] = useState({} as IPatientData);
    const [statusCode, setStatusCode] = useState<number>(200);
    const [showInPrint, setShowInPrint] = useState(false);
    const [remainingPatientDetailsLoadingSteps, setRemainingPatientDetailsLoadingSteps] = useState(
        Object.values(PatientDetailsLoadingStep)
    );

    const fileName = isEmpty(patientInfo)
        ? null
        : generateReportFileName(
              patientInfo.birthdate,
              patientInfo.createdDate,
              patientInfo.firstName,
              patientInfo.lastName
          );

    const completeLoadingStep = (value: PatientDetailsLoadingStep) => {
        setRemainingPatientDetailsLoadingSteps((steps) => steps.filter((step) => step !== value));
    };

    useEffect(() => {
        const { href, search } = window.location;

        if (!href?.includes('?')) {
            history.push(RoutePath.patients);
            return;
        }
        const { patientId, daysCount, displayOnPrint, timeZone } = checkUrlPatientDetails(search);

        setShowInPrint(displayOnPrint);

        if (authState != null) {
            if (authState.accessToken) {
                const oktaDataConfig = parseOktaData(authState);
                axios.defaults.headers.common.Authorization = oktaDataConfig.access;
            }

            (async () => {
                const patientData = await getPatientInfo(patientId, daysCount, timeZone);
                setStatusCode(patientData.statusCode);
                const { patientInfoData } = patientData;
                if (!patientInfoData) return;

                completeLoadingStep(PatientDetailsLoadingStep.FetchData);
                setPatientInfo(patientInfoData as IPatientData);
            })();
        }
    }, [history, authState]);

    if (statusCode >= 400) return <div className="request-error">Oops... Something went wrong</div>;

    return (
        <div className={clsx('container', 'patient-details', showInPrint ? 'display-on-print-only' : '')}>
            {remainingPatientDetailsLoadingSteps.length ? <div className="loading-content" /> : null}

            {!isEmpty(patientInfo) && (
                <>
                    <div id={reportFileNameElementId} className="hidden" data-testid={reportFileNameElementId}>
                        {fileName}
                    </div>

                    <Suspense
                        fallback={
                            <UnmountListeningWrapper
                                value={PatientDetailsLoadingStep.RenderSummary}
                                onUnmount={completeLoadingStep}
                            >
                                <Loading type="local" />
                            </UnmountListeningWrapper>
                        }
                    >
                        <ReportSummary data={patientInfo} />
                    </Suspense>

                    <>
                        <Suspense
                            fallback={
                                <UnmountListeningWrapper
                                    value={PatientDetailsLoadingStep.RenderDaily}
                                    onUnmount={completeLoadingStep}
                                >
                                    <Loading type="local" />
                                </UnmountListeningWrapper>
                            }
                        >
                            <ReportDaily data={patientInfo} />
                        </Suspense>

                        <Suspense
                            fallback={
                                <UnmountListeningWrapper
                                    value={PatientDetailsLoadingStep.RenderSettings}
                                    onUnmount={completeLoadingStep}
                                >
                                    <Loading type="local" />
                                </UnmountListeningWrapper>
                            }
                        >
                            <ReportSettings data={patientInfo} />
                        </Suspense>
                    </>
                </>
            )}
        </div>
    );
};

export default PatientDetails;
